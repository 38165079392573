import logo from '../assets/CODE.png'

import '../scss/header.scss'
const Header = () => {
	return (
		<header className='header'>
			{/* Logo i tekst */}
			<div className='header__content'>
				<img src={logo} alt='Logo' className='header__content--logo' />
				<h1 className='text-4xl font-bold'>Pomagam firmom zaistnieć w internecie!</h1>
			</div>
		</header>
	)
}

export default Header

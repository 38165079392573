import './scss/app.scss'
import Header from './components/Header'
import React, { Suspense, useCallback, lazy } from 'react'
import Particles from 'react-tsparticles'
import Loading from './components/Loading.jsx'
import { loadSlim } from 'tsparticles-slim'
// Ładowanie komponentów dynamicznie
const AboutLazy = lazy(() => import('./components/AboutSection.jsx'))
const TimelineLazy = lazy(() => import('./components/TimelineSection.jsx'))
const FaqLazy = lazy(() => import('./components/Faq.jsx'))
const ContactSectionLazy = lazy(() => import('./components/ContactSection.jsx'))
const FooterSectionLazy = lazy(() => import('./components/Footer.jsx'))
const CookieConsentLazy = lazy(() => import('./components/CookieConsent.jsx'))

function App() {
	const particlesInit = useCallback(async engine => {
		await loadSlim(engine)
	}, [])
	return (
		<>
			<Suspense fallback={<Loading />}>
				<Particles
					init={particlesInit}
					className='particles'
					options={{
						background: {
							color: 'transparent',
						},
						particles: {
							number: {
								value: 30,
								density: {
									enable: true,
									value_area: 800,
								},
							},
							shape: {
								type: 'circle',
							},
							opacity: {
								value: 0.5,
							},
							size: {
								value: 2,
							},
							move: {
								enable: true,
								speed: 2,
							},
							links: {
								enable: true,
								color: '#ffffff',
								distance: 150,
							},
						},
					}}
				/>
				<Header />
				<main>
					{/* Suspense z ekranem ładowania */}
					<AboutLazy />
					<TimelineLazy />
					<FaqLazy />
					<ContactSectionLazy />
					<CookieConsentLazy />
				</main>
				<FooterSectionLazy />
			</Suspense>
		</>
	)
}

export default App

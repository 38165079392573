// Loading.jsx
import React from 'react'

// Prosty spinner jako ekran ładowania
const Loading = () => {
	return (
		<div style={styles.container}>
			<p style={styles.par}>Ładowanie...</p>
		</div>
	)
}

// Style dla spinnera
const styles = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		flexDirection: 'column',
	},
	par: {
		fontSize: '24px',
		color: '#fff',
		fontFamily: 'Arial, sans-serif',
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: '20px',
		animation: 'fadeIn 1s ease-in-out',
		'@keyframes fadeIn': {
			'0%': { opacity: 0 },
			'100%': { opacity: 1 },
		},
	},
}

export default Loading
